.metric_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  span {
    font-size: 1.5rem;
    margin-top: -2rem !important;
    display: block;
  }
  h2 {
    font-size: 3.5rem;
    font-weight: 500;
    margin-top: 2rem;
  }
}
.metric_icon {
  width: 7rem;
}
.text_tooltip {
  color: #fff;
}
.custom_tooltip {
  background-color: rgba(0, 0, 0, 0.705);
  border-radius: 0.75rem;
  padding: 0.45rem 1rem;
}

// .pie_chart_container{
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
// }
// .pie_chart_wrapper{
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 50%;
// }

@media (max-width: 1100px) {
  .pie_chart_container {
    flex-direction: column;
    align-items: center;
  }
}
