.inner_card {
  background-color: #fff;
  padding: 1rem 2rem;
  border: 1px solid #d1d1d1;
  border-radius: 1rem;
}
.heading {
  font-weight: 600;
  font-size: 1.52rem;
}
