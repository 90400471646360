body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.previewBtnBackground {
  background-color: #f4f6f8;
  max-width: 300px;
  padding: 1.2rem;
  display: flex;
  align-items: center;
}
.previewBackground.greetings-templates .Polaris-Stack .Polaris-Stack__Item {
  margin-top: 2rem;
}
.btn-in-input {
  height: 1.6rem;
  width: 3.2rem;
  border-radius: 4px;
  border-color: #d2d2d2 !important;
  /* -webkit-box-shadow: 0 0 5px 0 #d3d3d3;
  box-shadow: 0 0 5px 0 #d3d3d3; */
}

@media screen and (max-width: 568px) {
  .previewBackground.chat-btn-templates .Polaris-Stack .Polaris-Stack__Item {
    width: 45%;
  }
}

@media screen and (max-width: 568px) {
  .previewBackground.chat-btn-templates .Polaris-Stack .Polaris-Stack__Item {
    width: 45%;
  }
}

.templateItem {
  margin-left: 1px;
}

.tmplinput {
  margin-left: 0px;
}

.tmplimg {
  vertical-align: top;
  margin-right: 10px;
}
.smallImg {
  width: 70px;
}
.custom-avatar-url-cont {
  display: flex;
  align-items: flex-start;
}
.toolTip {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  width: 30px;
}

/**
* GREETINGS PREVIEW
*/

.wa-chat-bubble-floating-popup {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  background: #fff;
  overflow: hidden;
  width: 188px;
  max-width: 100%;
  font-family: Open Sans;
  bottom: 20px;
  display: block;
  margin: 0px 6px;
  border-radius: 0px;
}

.wa-chat-bubble-header-common {
  text-align: left;
  color: #fff;
  padding: 13px;
  background: linear-gradient(110.56deg, #20802c 0%, #30bf42 100%);
}

.wa-chat-bubble-header-common.wavy::after {
  content: "";
  position: absolute;
  width: 188px;
  /* left: 0; */
  margin-left: -13px;
  border-image-source: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 24 150 28'%3E%3Cdefs%3E%3Cpath id='gentle-wave' d='M-160 46c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'%3E%3C/path%3E%3C/defs%3E%3Cg%3E%3Cuse xlink:href='%23gentle-wave' x='30' y='3' fill='%23fff'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
  border-image-slice: 0 2 100%;
  border-bottom: solid 13px;
}

.wa-chat-bubble-header-title {
  font-size: 16px;
}

.wa-chat-bubble-header-desc {
  margin-top: 6px;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  letter-spacing: 0.15px;
  line-height: 100%;
  text-align: left;
  color: #efefef;
  /*padding-right: 40px; */
}

.wa-chat-bubble-avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  overflow: hidden;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #20802c;
}

.wa-chat-bubble-avatar img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.wa-chat-bubble-chat .list-cs {
  font-size: 0.6rem;
  padding: 0.7rem;
  overflow: hidden;
  border-bottom: 2px solid #fafafa;
  background-color: lightgrey;
  display: flex;
  background: #fff;
  cursor: pointer;
  align-items: center;
}

.wa-chat-bubble-chat .list-cs:last-child {
  margin-bottom: 1.2rem;
}

.wa-chat-bubble-profile-name {
  display: block;
  font-size: 1.15rem;
  margin-block-start: 0.3em;
  margin-block-end: 0.2em;
}

.wa-chat-bubble-cs-profile p {
  font-size: 0.6em;
}

.wa-chat-bubble-whatsapp-avatar {
  position: relative;
  vertical-align: bottom;
  left: 1px;
  z-index: 99;
  height: 1rem;
  width: 1rem;
}

.wa-chat-bubble-cs-profile {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: #000;
  font-size: 14px;
  margin-top: 2px;
  text-align: left;
  flex: 1;
}

.wa-chat-bubble-cs-profile p {
  margin: 0;
  padding: 0;
  line-height: 1;
  color: #555555;
  font-weight: 200;
}

.wa-chat-widget-footer {
  display: flex;
  bottom: 0px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* font-family: Open Sans; */
  font-family: "Lato", Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 5px;
  color: #999999;
  width: 100%;
  height: 10px;
  background: #fafafa;
  vertical-align: middle;
  cursor: pointer;
  z-index: 100;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 1px 1px;
}

.wa-chat-bubble-chat {
  padding-bottom: 1.8rem;
}

.wa-chat-widget-footer-superlemon {
  color: #2eb840;
}

.wa-intercom .wa-chat-bubble-chat {
  margin: 0.9rem;
  margin-top: -1.8rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.wa-intercom .wa-chat-bubble-chat .list-cs:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.wa-intercom .wa-chat-bubble-chat .list-cs {
  /* padding: 16px 4px; */
}

.wa-intercom .wa-chat-widget-footer {
  box-shadow: none;
  background: transparent;
}

.wa-intercom .wa-chat-bubble-header-common {
  padding-bottom: 26px;
}

/***
* Share Btn Preview
*/
.wa-share-btn-container {
  width: auto;
  height: auto;
  margin: 0px;
  bottom: 50%;
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  padding: 1px 4px 1px;
  box-shadow: 0px 0px 5px 0px #d3d3d3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.wa-share-btn-cta {
  writing-mode: tb;
  margin: 0;
  margin-bottom: 6px;
  margin-top: 6px;
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-writing-mode: tb;
  -ms-writing-mode: tb;
  display: table;
  color: white;
}

.wa-share-btn-img {
  display: table;
  height: 19px;
  width: 19px;
  margin: auto;
  margin-bottom: 4px;
}

.wa-share-icon {
  background-size: cover;
  background: white;
}

.wa-share-mask {
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-image: url(https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa.svg?v=1586952948);
}

.previewCustomShareBtnBackground {
  background-color: #F4F6F8;
  max-width: 300px;
  padding: 1.2rem 3.2rem;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 568px) {
  .optin-number-page .Polaris-Popover__Content {
    min-width: 516px;
    min-height: 490px;
  }
}