@import "./assets/css/variables.scss";

@media (max-width: 600px) {
  .Polaris-Page {
    max-width: 95% !important;
  }
}

.Polaris-Frame__Main,
.Polaris-Navigation {
  background: white !important;
}
.Polaris-Page {
  .Polaris-Header-Title {
    font-size: 3rem;
  }
  .Polaris-Header-Title__SubTitle {
    margin-bottom: 0.8rem;
    color: #000;
  }
  .Polaris-Header-Title__SubTitle-light {
    margin-bottom: 0.8rem;
    color: rgb(100, 97, 97);
  }
}
.Polaris-DisplayText {
  line-height: 4rem;
}
.Polaris-Navigation__Item {
  font-weight: 400 !important;
}
.Polaris-Navigation__Item--selected {
  color: $primary-color !important;
}
.Polaris-Navigation__Item--selected::before {
  background-color: $primary-color !important;
}
.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg {
  fill: $primary-color !important;
}
// .Polaris-Popover {
// max-width: fit-content !important;
// }
.Polaris-Button {
  border-radius: 2rem !important;
}
.Polaris-Button--primary {
  border-radius: 2rem !important;
  background-color: $primary-color !important;
  box-shadow: none !important;
}
.Polaris-Button--primary.outline,
.Polaris-Button--primary.Polaris-Button--outline {
  background: none !important;
  border-radius: 2rem !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
  box-shadow: none !important;
}
.Polaris-Button--primary.Polaris-Button--loading {
  color: #02574280 !important;
}
.Polaris-Button--primary.outline:hover {
  border-radius: 2rem !important;
  border-color: $primary-color !important;
  background-color: $primary-color !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.Polaris-Banner--withinContentContainer {
  border-radius: 1rem !important;
}
.Polaris-Banner--statusSuccess.Polaris-Banner--withinContentContainer {
  background-color: #f1fae8 !important;
}
.release-heading h2 {
  color: #0e9b0e;
  font-size: 2rem;
  text-decoration: underline;
}
.text-center {
  text-align: center !important;
}
.m-1 {
  margin: 1rem 1rem !important;
}
.m-2 {
  margin: 2rem 2rem !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-1-half {
  margin-top: 0.5rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pr-2 {
  padding-right: 2rem !important;
}
.pt-0 {
  padding-top: 0rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pt-2 {
  padding-top: 1.5rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-2 {
  padding-bottom: 1.5rem !important;
}
.fs-xxlarge {
  font-size: 4rem !important;
}
.fs-xlarge {
  font-size: 3rem !important;
}
.fs-1 {
  font-size: 2.5rem !important;
}
.fs-2 {
  font-size: 2rem !important;
}
.fs-3 {
  font-size: 1.75rem !important;
}
.fs-4 {
  font-size: 1.5rem !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-6 {
  font-size: 1rem !important;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.w-80 {
  width: 80% !important;
}
.w-70 {
  width: 70% !important;
}
.w-60 {
  width: 60% !important;
}
.w-50 {
  width: 50% !important;
}
.w-52 {
  width: 53% !important;
}
.w-40 {
  width: 40% !important;
}
.width-30 {
  width: 30% !important;
}
.w-20 {
  width: 20% !important;
}
.w-15 {
  width: 15% !important;
}
.w-10 {
  width: 10% !important;
}
.w-1 {
  width: 15rem !important;
}
.w-2 {
  width: 20rem !important;
}
.h-1 {
  height: 15rem !important;
}
.h-2 {
  height: 20rem !important;
}
.h-3 {
  height: 30rem !important;
}
.r-0 {
  right: 0;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.alignEnd {
  text-align: end !important;
}
.break-word {
  word-break: break-word;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex-start {
  display: flex;
  justify-content: start;
  align-items: center;
}
.alignSelfStart {
  align-self: flex-start;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.vertical-align-center {
  align-items: center;
}
.text-primary {
  color: #5dc001 !important;
}
.strong {
  font-weight: 700 !important;
}
.font-weight-normal {
  font-weight: normal;
}
.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.pointer {
  cursor: pointer;
}
.pointerNone{
  cursor: not-allowed !important;
}
.checkoutOptinModalImage {
  width: 580px;
}
.checkoutOptinModalImageMoreWidth {
  width: 700px;
}
.overflow-wrap {
  overflow-wrap: break-word;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
.Polaris-Modal-Dialog__Modal {
  max-width: 82rem !important;
}
.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
  max-width: 46rem !important;
}
.imgchatscale1 {
  width: 162px;
}
.imgchatscale2 {
  width: 138px;
}
.imgchatscale3 {
  width: 58px;
}
.Polaris-ActionMenu-SecondaryAction {
  .Polaris-Button--outline {
    background: none !important;
    border-radius: 2rem !important;
    border-color: $primary-color !important;
    color: $primary-color !important;
    box-shadow: none !important;
    border: 1px solid $primary-color !important;

    svg {
      fill: #5dc001;
    }
  }
  .Polaris-Button--outline:hover {
    border-radius: 2rem !important;
    border-color: $primary-color !important;
    background-color: $primary-color !important;
    color: #ffffff !important;
    box-shadow: none !important;

    svg {
      fill: #ffffff;
    }
  }
}
.Polaris-ActionMenu-SecondaryAction {
  position: relative !important;
  right: 4rem !important;
}
.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
  background: $primary-color !important;
}
.Polaris-ActionMenu-RollupActions__RollupActivator {
  margin-right: 0.8rem !important;
}
.tooltip-overlay {
  position: absolute;
  right: 18%;
  top: 5rem;
  z-index: 100;
}
@media (max-width: 1600px) {
  .tooltip-overlay {
    right: 10%;
  }
}
@media (max-width: 1400px) {
  .tooltip-overlay {
    right: 6%;
  }
}
@media (max-width: 1300px) {
  .tooltip-overlay {
    right: 4%;
  }
}
@media (max-width: 770px) {
  .tooltip-overlay {
    right: 20%;
  }
}

@media (max-width: 420px) {
  .tooltip-overlay {
    right: 0.5%;
  }
}
.increase-top {
  top: 22rem;
}

@media (max-width: 1050px) {
  .increase-top {
    top: 24rem;
  }
}

@media (max-width: 875px) {
  .increase-top {
    top: 26rem;
  }
}

@media (max-width: 560px) {
  .increase-top {
    top: 31.8rem;
  }
}

@media (max-width: 405px) {
  .increase-top {
    top: 33rem;
  }
}

@media (max-width: 380px) {
  .increase-top {
    top: 35.8rem;
  }
}
@media (max-width: 340px) {
  .increase-top {
    top: 41rem;
  }
}

.tooltip-outer-container .icon .tooltip-svg-icon {
  width: 2.9rem;
}
.tooltip-outer-container {
  display: flex;
  justify-content: flex-end;
}
.tooltip-hover-content {
  box-shadow: 2px 3px 14px -6px rgba(0, 0, 0, 0.91);
  -webkit-box-shadow: 2px 3px 14px -6px rgba(0, 0, 0, 0.91);
  -moz-box-shadow: 2px 3px 14px -6px rgba(0, 0, 0, 0.91);
  border-radius: 0.5rem;
  background-color: #fff;
}
.tooltip-hover-content .inner-content {
  display: none;
  padding: 2.8rem 2.4rem;
  max-width: 30rem;
}

.tooltip-outer-container:hover + .tooltip-hover-content .inner-content {
  display: block;
}
.tooltip-hover-content:hover .inner-content {
  display: block;
}

.Polaris-Tooltip-TooltipOverlay__Content {
  max-width: max-content !important;
  background-color: #212b36 !important;
  color: #ffffff !important;
}
.calloutCardContentContainer .Polaris-CalloutCard__Buttons .Polaris-Button {
  border-radius: 2rem !important;
  background-color: $primary-color !important;
  box-shadow: none !important;
  color: #ffffff !important;
}
.calloutCardContentContainer .Polaris-CalloutCard__Image {
  width: 26% !important;
  margin: 0 16px;
  opacity: 0.8;
}
.calloutCardContentContainer .Polaris-CalloutCard__Title {
  margin-bottom: 1.4rem !important;
}
.calloutCardContentContainer .Polaris-TextContainer {
  color: #46494a !important;
}
.pre-line {
  white-space: pre-line;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.Polaris-TextField__Prefix,
.Polaris-TextField__Suffix {
  pointer-events: all !important;
}
.Polaris-Icon--colorPrimary .Polaris-Icon__Svg {
  fill: $primary-color !important;
}
.Polaris-Icon--colorWarning .Polaris-Icon__Svg {
  fill: red !important;
}
.Polaris-Icon--colorSubdued .Polaris-Icon__Svg {
  fill: gray !important;
}
.no-border {
  border: none !important;
}
.card-integration {
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 2rem;
}
.powered-by {
  .Polaris-FooterHelp__Content {
    border: 0.1rem solid #dfe3e8 !important;
    border-radius: 999px !important;
  }
  .Polaris-FooterHelp__Icon {
    opacity: 0 !important;
    margin-right: 0.8rem;
  }
}
.v-align {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.login-container {
  height: 100vh;
  .login-content {
    align-self: center;
  }
  .login-image {
    align-self: stretch;
    img {
      object-fit: cover;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }
}

.login {
  margin-bottom: 10em;
  margin-top: 2em;
  .Polaris-TextField {
    width: 60%;
    margin: 0 auto;
    .Polaris-TextField__Backdrop {
      border-radius: 2rem;
    }
  }

  .Polaris-InlineError {
    display: inline-flex;
  }
}
.upgrade-text {
  width: 30rem !important;
}
.Polaris-Link-Updated-Color {
  color: #5dc001 !important;
}
.Polaris-Card {
  background-color: #fafbfd !important;
}
.celebrate-svg-icon {
  width: 5rem;
}
.bell-icon img {
  width: 2.7rem;
}

.Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg {
  fill: rgba(0, 128, 96, 1) !important;
}
