.flex-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.template {
    .Polaris-Button {
        min-width: 12rem;
    }

    .Polaris-Select {
        border-radius: 2rem !important;
    }
}

.template-info-container {
    display: flex;
    align-items: center;
    .template-info {
        justify-content: center;
        min-width: 50%;
        width: 50%;
        overflow-wrap: break-word;
        margin-right: 1rem;
        .Polaris-TextStyle--variationSubdued{
            font-size: 1.2rem;
            margin: 0;
        }
    }
}

@media all and (max-width: 500px) { 
    .template-info-container{
        margin-left: 2rem;
    }
}
@media all and (max-width: 1070px) { 
    .flex-col{
        margin-top: 1rem;
        flex-direction: row;
        justify-content: end;
    }
    .delete-button-container {
        margin-left: 0.7rem;
    }
}
.react-switch-handle{
    width: 28px !important;
    height: 28px !important;
}