.templateItem {
    margin-left: 1px;
}
.templateItem.greetings .wa-chat-bubble-floating-popup img {
    width: auto !important;
}
.templateItem.greetings input{
    margin-right: 1.3rem;
}
.templateItem.greetings img{
    width: 192px;
    max-width: 100%;
}
.templateItem.share-template-btns input{
    margin-right: 0.8rem;
}
.greetings-widget-setting-toggle {
    margin: 2rem 0;
}
.greetings-widget-setting-toggle .Polaris-SettingAction {
    align-items: normal;
}
.greetings-widget-setting-toggle .Polaris-SettingAction .previewBtnBackground img{
    width: 75%;
}