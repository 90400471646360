@import "../../assets/css/variables.scss";

.basic_info_plans {
  .Polaris-Heading {
    text-align: center;
  }
  a {
    color: #5ec128 !important;
  }
  .min-height-4 {
    min-height: 4rem;
  }
  .basic-tile {
    min-height: 32rem;
    position: relative;
    .absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .align-at-top {
      top: 0;
      border-radius: 0;
    }
    .align-at-bottom {
      bottom: 0;
    }
    .plan-active-btn {
      border-radius: 4px !important;
      padding: 0.2rem 1.8rem !important;
      min-height: 2.4rem !important;
      pointer-events: none;
    }
  }
  .plan_price_1 {
    text-align: center;
    padding: 1rem 0;
    color: $primary-color;
    font-weight: bold;
    font-size: xx-large;
    margin-top: 1rem;
  }
  .plan_price_month_1 {
    text-align: center;
    padding: 2rem 0;
    color: $primary-color;
    font-weight: bold;
    font-size: medium;
  }
  .plan_price_1_strike_through {
    text-align: center;
    padding: 1rem 0;
    font-size: 2rem;
    color: #8b8b8b;
    font-weight: normal;
    padding-top: 0;
    font-size: 1.8rem;
  }
  .plan_price_month_1_strike_through {
    text-align: center;
    color: gray;
    font-weight: normal;
  }

  .sup {
    vertical-align: super;
    font-size: large;
  }
  .Polaris-Icon__Svg {
    fill: white !important;
  }
}

.detailed_plan_info {
  .header_only {
    font-weight: bold;
  }
  .plan_price_1 {
    text-align: center;
    padding-top: 1rem;
    color: $primary-color;
    font-weight: bold;
    font-size: large;
  }
  .plan_price_month_1 {
    text-align: center;
    padding-top: 1rem;
    color: $primary-color;
    font-size: small;
  }

  .sup {
    vertical-align: super;
    font-size: small;
  }
  .border-2 {
    border-width: 1.5px;
  }
  .Polaris-List {
    list-style-type: none !important;
  }
  .Polaris-List li::before {
    content: "\25E6" !important;
    color: $primary-color !important;
    padding-right: 1rem;
    font-size: xx-large;
    margin-left: -2rem;
    vertical-align: middle;
  }
  .Polaris-DataTable__Cell {
    white-space: normal !important;
  }
  .highlight {
    background-color: #f1fae8;
  }
}

.close-btn {
  border-radius: 50% !important;
  background-color: black !important;
  margin-left: -2rem !important;
  margin-top: -1rem !important;
  padding: 0.8rem !important;
  height: 2.8rem !important;
  position: absolute !important;
  right: 0;
  .Polaris-Icon {
    width: 1.2rem !important;
  }
  .Polaris-Icon--colorBase svg {
    fill: #fff !important;
  }
}

.modal-container {
  max-width: 60rem !important;
  .Polaris-Modal-Footer {
    border: 0 !important;
    align-self: center !important;
    justify-content: center !important;
  }
  .d-content {
    display: contents;
  }
  .Polaris-Modal-CloseButton::after {
    pointer-events: unset;
  }
}

.strike-through {
  text-decoration: line-through;
}
.pricing-color-strike-through {
  color: #8b8b8b;
  margin-right: 0.4rem;
}
